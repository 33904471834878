// src/app.component.tsx
import React from "react";
import {
  ThemeProvider,
  ErrorBoundary,
  ApiProvider,
  LayoutWrapper,
  AuthProvider,
  ToastProvider,
  getConfig,
  darkTheme,
  defaultTheme,
  ModalProvider,
} from "@fraktion/core-frontend";
import { BrowserRouter as Router } from "react-router-dom";
import { routes } from "./routing/route.config";

export const App: React.FC = () => {
  const config = getConfig();
  const initialTheme =
    config.DEFAULT_THEME === "dark" ? darkTheme : defaultTheme;

  return (
    <ThemeProvider initialTheme={initialTheme} config={config}>
      <ToastProvider>
        <Router>
          <ErrorBoundary>
            <ApiProvider>
              <AuthProvider>
                <ModalProvider>
                  <LayoutWrapper routes={routes} />
                </ModalProvider>
              </AuthProvider>
            </ApiProvider>
          </ErrorBoundary>
        </Router>
      </ToastProvider>
    </ThemeProvider>
  );
};

export default App;
