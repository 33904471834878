import { DeploymentConfig } from "@fraktion/core-frontend";
import { AmplifyProvider } from "../amplify/amplify.provider";

const deploymentConfig: DeploymentConfig = {
  environment: import.meta.env.VITE_ENVIRONMENT || "development",
  region: import.meta.env.VITE_AWS_REGION,
  stage: import.meta.env.VITE_STAGE || "development",
  apiEndpoints: {
    main: import.meta.env.VITE_API_BASE_URL,
    auth: import.meta.env.VITE_AUTH_BASE_URL,
    media: import.meta.env.VITE_MEDIA_BASE_URL,
  },
  storage: {
    bucket: import.meta.env.VITE_S3_BUCKET,
    region: import.meta.env.VITE_AWS_REGION,
    cloudfront: {
      domain: import.meta.env.VITE_CLOUDFRONT_DOMAIN,
      distributionId: import.meta.env.VITE_CLOUDFRONT_DISTRIBUTION_ID,
    },
  },
};

export const deploymentProvider = new AmplifyProvider(deploymentConfig);
