import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { loadTranslations } from "./i18n-loader";

export const i18n = i18next.createInstance();

export async function initializeI18n() {
  await i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: "fr",
      supportedLngs: ["en", "fr", "es", "zh"],
      load: "languageOnly",
      defaultNS: "common",
      detection: {
        order: ["localStorage", "navigator"],
        lookupLocalStorage: "i18nextLng",
        caches: ["localStorage"],
      },
      interpolation: {
        escapeValue: false,
      },
    });

  const currentLang = i18n.language || navigator.language.split("-")[0] || "fr";
  await loadTranslations(currentLang);

  i18n.on("languageChanged", (newLang) => {
    loadTranslations(newLang);
  });

  return i18n;
}
