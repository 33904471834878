// src/core-config.ts
import { initializeConfig } from "@fraktion/core-frontend";

initializeConfig({
  MODE: import.meta.env.MODE,
  AUTH_TOKEN: import.meta.env.VITE_AUTH_TOKEN,
  API_BASE_URL: import.meta.env.VITE_API_BASE_URL || "",
  AUTH_BASE_URL: import.meta.env.VITE_AUTH_BASE_URL || "",
  AUTH_TYPE: import.meta.env.VITE_AUTH_TYPE || "jwt",
  AUTH_STORAGE: import.meta.env.VITE_AUTH_STORAGE || "local",
  WEBSITE_URL: import.meta.env.VITE_WEBSITE_URL || "",
  STRIPE_PUBLIC_KEY: import.meta.env.VITE_STRIPE_PUBLIC_KEY || "",
  DEFAULT_THEME: import.meta.env.VITE_DEFAULT_THEME || "light",
  APP_NAME: import.meta.env.VITE_APP_NAME || "",
  TENANT_ID: import.meta.env.VITE_TENANT_ID || "",
  ONBOARDING: {
    ENABLED: import.meta.env.VITE_ONBOARDING_ENABLED === "true",
    STEPS: import.meta.env.VITE_ONBOARDING_STEPS?.split(",") || [],
  },
  JWT: {
    REFRESH_TOKEN_KEY:
      import.meta.env.VITE_JWT_REFRESH_TOKEN_KEY || "refresh_token",
    ACCESS_TOKEN_KEY:
      import.meta.env.VITE_JWT_ACCESS_TOKEN_KEY || "access_token",
  },
});
