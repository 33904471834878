import { Amplify } from "@aws-amplify/core";
import { BaseDeploymentProvider } from "@fraktion/core-frontend";
import { createAmplifyConfig } from "./amplify.config";

export class AmplifyProvider extends BaseDeploymentProvider {
  name = "amplify";

  async initialize(): Promise<void> {
    console.log("Initializing Amplify with config:", this.config); // Debug log
    const amplifyConfig = createAmplifyConfig(this.config);
    console.log("Amplify config:", amplifyConfig); // Debug log
    Amplify.configure(amplifyConfig);
  }

  getEnvironmentVariables(): Record<string, string> {
    return {
      ...super.getEnvironmentVariables(),
      VITE_AUTH_TYPE: import.meta.env.VITE_AUTH_TYPE || "jwt",
      VITE_PROVIDER: this.name,
      VITE_AWS_REGION: this.config.region || "",
      VITE_S3_BUCKET: this.config.storage?.bucket || "",
    };
  }
}
