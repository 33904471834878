import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  PageHeader,
  KPICard,
  LineChart,
  EmptyState,
  InvestmentDistribution,
  SelectOption,
  StatCardProps,
} from "@fraktion/core-frontend";

export const DashboardPage: React.FC = () => {
  const { t } = useTranslation(["dashboard", "common"]);
  const [startDate, setStartDate] = useState(new Date("2023-09-27"));
  const [endDate, setEndDate] = useState(new Date("2024-09-27"));
  const [selectedCampaign, setSelectedCampaign] = useState("all");
  const navigate = useNavigate();

  const investmentDistributionData = {
    data: {
      labels: [
        "Résidentiel Premium",
        "Bureaux Centre-Ville",
        "Commerce Piéton",
        "Logistique Urbaine",
        "Coliving",
      ],
      datasets: [
        {
          data: [35.8, 25.4, 18.7, 12.3, 7.8],
          backgroundColor: [
            "#10100F",
            "#C9C2C2",
            "#FFD700",
            "#6E6E6E",
            "#FF7D00",
          ],
          hoverBackgroundColor: [
            "#10100F",
            "#C9C2C2",
            "#FFD700",
            "#6E6E6E",
            "#FF7D00",
          ],
        },
      ],
    },
    // Represents month-over-month percentage changes
    percentageChanges: [3.2, -1.5, 4.8, 2.1, -0.9],
  };

  const amountCollectedChartData = {
    labels: [
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
    ],
    datasets: [
      {
        label: t("dashboard.campaignOne"),
        data: [100, 220, 180, 200, 280, 350, 370, 320, 270, 160, 100, 140, 300],
        borderColor: "#6E6E6E",
        backgroundColor: "#1B59F8",
        gradientStops: {
          start: "rgba(255, 215, 0, 1)",
          middle: "rgba(255, 215, 0, 1)",
          end: "rgba(255, 215, 0, 0)",
        },
        fill: true,
        order: 2,
      },
      {
        label: t("dashboard.campaignTwo"),
        data: [50, 100, 80, 180, 220, 280, 300, 240, 220, 180, 160, 140, 200],
        borderColor: "#FF7D00",
        backgroundColor: "#11A2B6",
        gradientStops: {
          start: "rgba(255, 125, 0, 1)",
          middle: "rgba(255, 125, 0, 0.1)",
          end: "rgba(255, 125, 0, 0)",
        },
        fill: true,
        order: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
        align: "start" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "rectRounded",
          padding: 20,
          font: {
            size: 12,
          },
          boxWidth: 16,
          boxHeight: 16,
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        radius: 0,
      },
    },
  };

  const campaignOptions: SelectOption[] = [
    { value: "all", label: t("common:investments.allInvestments") },
    { value: "investmentA", label: t("common:investments.investmentA") },
    { value: "investmentB", label: t("common:investments.investmentB") },
  ];

  const kpiData: StatCardProps[] = [
    {
      title: t("dashboard.investedAmount"),
      value: "0€",
      icon: "user",
      linkText: t("common:common.seeMore"),
      description: t("dashboard.investedAmountDescription"),
      link: "/",
    },
    {
      title: t("dashboard.averageYield"),
      value: "0€",
      icon: "check",
      linkText: t("common:common.seeMore"),
      description: t("dashboard.receivedRevenuesDescription"),
      link: "/",
    },
    {
      title: t("dashboard.investors"),
      value: "0%",
      icon: "wallet",
      linkText: t("common:common.seeMore"),
      description: t("dashboard.averageYieldDescription"),
      link: "/",
    },
  ];

  return (
    <div className="space-y-8">
      <PageHeader title={t("dashboard.dashboardTitle")} />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {kpiData.map((kpi, index) => (
          <KPICard key={index} {...kpi} />
        ))}
      </div>
      <div className="grid grid-cols-3 gap-8 h-full">
        <div className="col-span-2 flex flex-col">
          <div className="col-span-2 flex flex-col">
            <LineChart
              title={t("dashboard.receivedRevenues")}
              mainValue={150042}
              changeValue={3.5}
              isPositiveChange={true}
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
              selectedFilter={[selectedCampaign]}
              filterOptions={campaignOptions}
              onFilterChange={(value) => setSelectedCampaign(value[0])}
              chartData={amountCollectedChartData}
              chartOptions={chartOptions}
              renderEmptyState={() => (
                <EmptyState
                  icon="coins"
                  title={t("dashboard.noRevenuesTitle")}
                  description={t("dashboard.noRevenuesDescription")}
                  primaryAction={{
                    label: t("dashboard.projectInvestment"),
                    onClick: () => {
                      navigate("/marketplace");
                    },
                    icon: "plus",
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="col-span-1 flex flex-col">
          <InvestmentDistribution
            data={investmentDistributionData.data}
            percentageChanges={investmentDistributionData.percentageChanges}
          />
        </div>
      </div>
      {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <RecentOperations />
        <MyInvestments />
      </div> */}
    </div>
  );
};

export default DashboardPage;
