import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./app.component";
import { initializeI18n as initializeCoreI18n } from "@fraktion/core-frontend";
import { initializeI18n as initializeB2CI18n } from "./i18n/i18n.config";
import "@fraktion/core-frontend/dist/style.css";
import "./core.config";
import { deploymentProvider } from "@/config/deployment";

async function initializeApp() {
  // Initialize core i18n first
  initializeCoreI18n();
  // Then initialize B2C i18n with overrides
  await initializeB2CI18n();

  try {
    await deploymentProvider.initialize();
    ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  } catch (error) {
    console.error("Failed to initialize the application:", error);
  }
}

initializeApp();
