import { DeploymentConfig } from "@fraktion/core-frontend";

type AmplifyConfig = {
  API?: {
    REST?: {
      [key: string]: {
        endpoint: string;
        region?: string;
      };
    };
  };
  Storage?: {
    AWSS3?: {
      bucket?: string;
      region?: string;
      customDomain?: {
        domainName?: string;
        region?: string;
      };
    };
  };
};

export function createAmplifyConfig(config: DeploymentConfig): AmplifyConfig {
  return {
    API: {
      REST: {
        main: {
          endpoint: config.apiEndpoints.main,
          region: config.region,
        },
        ...(config.apiEndpoints.media && {
          media: {
            endpoint: config.apiEndpoints.media,
            region: config.region,
          },
        }),
      },
    },
    Storage: config.storage && {
      AWSS3: {
        bucket: config.storage.bucket,
        region: config.storage.region || config.region,
        customDomain: config.storage.cloudfront?.domain
          ? {
              domainName: config.storage.cloudfront.domain,
              region: config.storage.region || config.region,
            }
          : undefined,
      },
    },
  };
}
