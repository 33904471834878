import { i18n } from "./i18n.config";
import { translations as coreTranslations } from "@fraktion/core-frontend";

export async function loadTranslations(lang: string) {
  try {
    const normalizedLang = lang.split("-")[0];

    // Load core translations first
    const coreLangTranslations =
      coreTranslations[normalizedLang as keyof typeof coreTranslations];
    if (coreLangTranslations) {
      Object.entries(coreLangTranslations).forEach(([namespace, content]) => {
        // Deep merge for dashboard namespace
        if (namespace === "dashboard") {
          i18n.addResourceBundle(
            normalizedLang,
            namespace,
            content,
            true,
            true
          );
        } else {
          i18n.addResourceBundle(
            normalizedLang,
            namespace,
            content,
            true,
            false
          );
        }
      });
    }

    // Load local translations (will override core translations)
    const localModules = import.meta.glob("./translations/**/*.json", {
      eager: true,
    });

    Object.entries(localModules).forEach(([path, module]) => {
      if (path.includes(`/${normalizedLang}/`)) {
        const namespace = path.split("/").pop()?.replace(".json", "");
        if (namespace) {
          // Always deep merge local translations
          i18n.addResourceBundle(
            normalizedLang,
            namespace,
            (module as any).default,
            true,
            true
          );
        }
      }
    });

    await i18n.reloadResources(normalizedLang);
  } catch (error) {
    console.error("Error loading translations:", error);
  }
}
